import { Box, useTheme } from '@mui/material';
import { useContext } from 'react';
import SiteLookupContext from '../context/SiteLookupContext';
import SiteLookupMapContext from '../context/SiteLookupMapContext';
import useSiteLookupList from '../list/useSiteLookupList';
import SiteLookupMap from '../map/SiteLookupMap';

const MainContent = () => {
  const { filters } = useContext(SiteLookupContext);
  const { isMapExtended } = useContext(SiteLookupMapContext);
  const { zIndex } = useTheme();

  const {
    data: { sites },
    List,
    state: { fetching },
  } = useSiteLookupList({ filters });

  return (
    <Box display="flex" height="100%" width="100%">
      <Box
        sx={{
          zIndex: zIndex.mobileStepper,
          boxShadow: '0px 3px 3px rgb(0 0 0 / 12%)',
        }}
      >
        <Box
          width={{ xs: 300, lg: 400, xl: 508 }}
          sx={{
            display: isMapExtended ? 'none' : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingX: 2,
          }}
        >
          <List />
        </Box>
      </Box>

      <Box
        sx={{
          position: 'sticky',
          top: 'calc(var(--top-bar-height) + var(--search-bar-height) + 1px)',
          height: 'calc(100vh - (var(--top-bar-height) + var(--search-bar-height) + 1px))',
          width: '100%',
        }}
      >
        <SiteLookupMap fetchingSites={fetching} sites={sites} />
      </Box>
    </Box>
  );
};

export default MainContent;
