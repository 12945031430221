import { SearchOutlined } from '@mui/icons-material';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  onClick: MouseEventHandler<HTMLLIElement> | undefined;
};

const SearchMenuItem = ({ onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <MenuItem dense onClick={onClick}>
      <ListItemIcon color="primary">
        <SearchOutlined color="action" fontSize="inherit" sx={{ opacity: 0.75 }} />
      </ListItemIcon>
      <Typography variant="body2">{t('siteLookup.contextMenu.search')}</Typography>
    </MenuItem>
  );
};

export default SearchMenuItem;
