import { ContentCopyOutlined } from '@mui/icons-material';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { MouseEventHandler } from 'react';

type Props = {
  lat: number;
  lng: number;
  onClick: MouseEventHandler<HTMLLIElement> | undefined;
};

const CoordinatesMenuItem = ({ lat, lng, onClick }: Props) => (
  <MenuItem dense onClick={onClick}>
    <ListItemIcon color="primary">
      <ContentCopyOutlined color="action" fontSize="inherit" sx={{ opacity: 0.75 }} />
    </ListItemIcon>
    <Typography variant="body2">
      {lat.toFixed(5)}, {lng.toFixed(5)}
    </Typography>
  </MenuItem>
);

export default CoordinatesMenuItem;
