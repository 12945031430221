import { ComingSoonPin, SelectedPin, TrackedPin, UntrackedPin } from '@application/assets';
import { SiteStatus, TrackingState } from '@domain/graphql.types';
import { useTheme } from '@mui/material';

type Props = {
  height?: number;
  includedInReport: boolean;
  isSelected?: boolean;
  state: TrackingState;
  status: SiteStatus;
  width?: number;
};

const MapPin = ({ height, includedInReport, isSelected, state, status, width }: Props) => {
  const {
    palette: { text },
  } = useTheme();

  if (isSelected) {
    return <SelectedPin height={height} width={width} />;
  }

  const fill = includedInReport ? undefined : text.secondary;

  if (status === SiteStatus.ComingSoon) {
    return <ComingSoonPin fill={fill} height={height} width={width} />;
  }

  switch (state) {
    case TrackingState.Tracked:
      return <TrackedPin fill={fill} height={height} width={width} />;
    case TrackingState.NotTracked:
      return <UntrackedPin fill={fill} height={height} width={width} />;
  }
};

export default MapPin;
