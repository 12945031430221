import 'mapbox-gl/dist/mapbox-gl.css';

import { CurrentPositionPin } from '@application/assets';
import { BulkSearchAddressItem } from '@domain/graphql.types';
import { Box } from '@mui/material';
import { useCallback, useRef } from 'react';
import ReactMap, { NavigationControl, Marker, Source, Layer } from 'react-map-gl';
import { useResizeObserver } from 'usehooks-ts';
import { FileState } from '../hooks/useBulkSearch';
import useBulkMap from './useBulkMap';
import {
  ExtendMapControl,
  clusterCountLayer,
  clusterLayer,
  comingSoonNotIncludedPointLayer,
  comingSoonPointLayer,
  trackedNotIncludedPointLayer,
  trackedPointLayer,
  untrackedNotIncludedPointLayer,
  untrackedPointLayer,
} from '@application/components';

type Props = {
  addresses: readonly BulkSearchAddressItem[];
  fileState: FileState;
  isMapExtended: boolean;
  toggleIsMapExtended: () => void;
};

const BulkMap = ({ addresses, fileState, isMapExtended, toggleIsMapExtended }: Props) => {
  const containerRef = useRef(null);

  const {
    map: { accessToken, style },
    refs: { map },
    state: { allSites, mapCenter, mapZoom },
  } = useBulkMap({ addresses, fileState });

  const onResize = useCallback(() => {
    map.current?.resize();
  }, [map]);

  useResizeObserver({ ref: containerRef, onResize });

  return (
    <Box ref={containerRef} sx={{ height: '100%', width: '100%' }}>
      <ReactMap
        ref={map}
        mapStyle={style}
        initialViewState={{
          latitude: mapCenter.latitude,
          longitude: mapCenter.longitude,
          zoom: mapZoom,
        }}
        dragRotate={false}
        mapboxAccessToken={accessToken}
      >
        {addresses?.map(({ coordinates: { latitude, longitude }, userDefinedId }) => (
          <Marker key={userDefinedId} latitude={latitude} longitude={longitude} scale={1}>
            <CurrentPositionPin />
          </Marker>
        ))}

        <Source id="all_sites" type="geojson" data={allSites} cluster={true} clusterMaxZoom={14} clusterRadius={50}>
          <Layer {...clusterLayer} />
          <Layer {...clusterCountLayer} />
          <Layer {...comingSoonPointLayer} />
          <Layer {...comingSoonNotIncludedPointLayer} />
          <Layer {...trackedPointLayer} />
          <Layer {...trackedNotIncludedPointLayer} />
          <Layer {...untrackedPointLayer} />
          <Layer {...untrackedNotIncludedPointLayer} />
        </Source>

        <NavigationControl position="bottom-right" showCompass={false} />

        <ExtendMapControl handleToggleMapExtended={toggleIsMapExtended} isMapExtended={isMapExtended} />
      </ReactMap>
    </Box>
  );
};

export default BulkMap;
