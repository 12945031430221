import React, { useContext, useMemo } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '@mui/material';
import { LinkComponent } from '@application/components';
import { Routes } from '@application/routes';
import SiteLookupContext from '../context/SiteLookupContext';
import { UserRole } from '@domain/graphql.types';
import { UserContext } from '@application/contexts';
import SiteLookupMapContext from '../context/SiteLookupMapContext';

type Props = {
  children: React.ReactElement;
};

const DownloadButtonTooltip = ({ children }: Props) => {
  const { t } = useTranslation();

  const { hasSitesIncludedInReport } = useContext(SiteLookupMapContext);
  const { isUserOnFreePlan, remainingSearches } = useContext(SiteLookupContext);
  const { role } = useContext(UserContext);

  const showLinkToSubscription = role === UserRole.Owner;

  const freeUserHasNoSearches = useMemo(() => !!isUserOnFreePlan && (remainingSearches || 0) <= 0, [isUserOnFreePlan, remainingSearches]);

  const title = useMemo(() => {
    if (!hasSitesIncludedInReport) {
      return t('siteLookup.downloadReportNoSitesIncluded');
    }

    if (freeUserHasNoSearches) {
      return (
        <Trans
          i18nKey={'siteLookup.downloadReportTooltipLimit'}
          t={t}
          components={{
            LearnMore: showLinkToSubscription ? (
              <Link color="inherit" component={LinkComponent} href={Routes.TENANT_SUBSCRIPTION}>
                {t('siteLookup.learnMore')}
              </Link>
            ) : (
              <></>
            ),
          }}
        />
      );
    }

    return null;
  }, [freeUserHasNoSearches, hasSitesIncludedInReport, showLinkToSubscription, t]);

  return (
    <Tooltip disableInteractive={!freeUserHasNoSearches && hasSitesIncludedInReport} placement="top" title={title}>
      {children}
    </Tooltip>
  );
};

export default DownloadButtonTooltip;
