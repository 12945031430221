import { useTranslation } from 'react-i18next';
import Snackbar from './Snackbar';

type RenderSnackbarProps = {
  title: string;
  message?: string;
  severity: 'error' | 'warning' | 'success' | 'info';
  open: boolean;
  onClose: () => void;
};

const renderSnackbar = ({ message, onClose, open, severity, title }: RenderSnackbarProps) => (
  <Snackbar title={title} message={message} severity={severity} open={open} onClose={onClose} />
);

type SnackbarProps = {
  message?: string;
  onClose: () => void;
  open?: boolean;
  title?: string;
};

export const ErrorSnackbar = ({ message, onClose, open = true, title }: SnackbarProps) => {
  const { t } = useTranslation();

  return renderSnackbar({
    message: message || t('snackbar.error.default.message'),
    onClose,
    open,
    severity: 'error',
    title: title || t('snackbar.error.default.title'),
  });
};

export const InfoSnackbar = ({ message, onClose, open = true, title }: SnackbarProps) => {
  const { t } = useTranslation();

  return renderSnackbar({
    message: message || '',
    onClose,
    open,
    severity: 'info',
    title: title || t('snackbar.info.default.title'),
  });
};

export const SuccessSnackbar = ({ message, onClose, open = true, title }: SnackbarProps) => {
  const { t } = useTranslation();

  return renderSnackbar({
    message,
    onClose,
    open,
    severity: 'success',
    title: title || t('snackbar.success.default.title'),
  });
};
