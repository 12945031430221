import { PhoneField } from '@application/components';
import { LoadingButton } from '@mui/lab';
import { Link, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useCountdown } from 'usehooks-ts';
import { OnboardingSchemaFormFields } from './OnboardingForm.schema';

const INTERVAL_BETWEEN_RESEND_IN_SECONDS = 30;

type Props = {
  changePhone: () => void;
  isSubmitting: boolean;
  isSendingVerification: boolean;
  sendVerificationCode: () => void;
  isVerifying: boolean;
};

const PhoneVerification = ({ changePhone, isSubmitting, isSendingVerification, sendVerificationCode, isVerifying }: Props) => {
  const [count, { resetCountdown, startCountdown }] = useCountdown({
    countStart: INTERVAL_BETWEEN_RESEND_IN_SECONDS,
    intervalMs: 1000,
    countStop: 0,
  });

  const { t } = useTranslation();

  const { typography } = useTheme();

  const {
    control,
    register,
    formState: { errors },
    watch,
  } = useFormContext<OnboardingSchemaFormFields>();

  const phone = watch('phone');

  const handleResendCode = useCallback(() => {
    resetCountdown();
    startCountdown();

    sendVerificationCode();
  }, [resetCountdown, sendVerificationCode, startCountdown]);

  useEffect(() => {
    if (isVerifying) {
      resetCountdown();
      startCountdown();
    }
  }, [resetCountdown, startCountdown, isVerifying]);

  return (
    <Stack gap={3} alignItems="center" width={400}>
      {!isVerifying && (
        <>
          <Typography variant="h1" sx={{ marginTop: 0.5, fontSize: typography.h3.fontSize, fontWeight: typography.fontWeightMedium }}>
            {t('onboarding.phoneVerification.title')}
          </Typography>

          <Typography variant="body1">{t('onboarding.phoneVerification.subscript')}</Typography>

          <Stack gap={2}>
            <Controller
              control={control}
              name="phone"
              render={({ field: { value, onBlur, onChange } }) => (
                <PhoneField
                  label={t('labels.phone')}
                  variant="outlined"
                  fullWidth
                  error={!!errors.phone?.message}
                  helperText={errors.phone?.message && t(errors.phone?.message, { field: t('labels.phone'), max: 36 })}
                  onChange={onChange}
                  value={value}
                  onBlur={onBlur}
                />
              )}
            />

            <Typography variant="body2" align="center">
              {t('onboarding.phoneVerification.consent')}
            </Typography>

            <LoadingButton variant="contained" size="large" fullWidth onClick={sendVerificationCode} loading={isSendingVerification}>
              {t('button.sendVerificationCode')}
            </LoadingButton>
          </Stack>
        </>
      )}

      {isVerifying && (
        <>
          <Typography variant="h1" sx={{ marginTop: 0.5, fontSize: typography.h3.fontSize, fontWeight: typography.fontWeightMedium }}>
            {t('onboarding.phoneVerification.enterCode')}
          </Typography>

          <Stack direction="row">
            <Typography variant="body1">{t('onboarding.phoneVerification.sent')}&nbsp;</Typography>

            <Typography marginRight={0.75} fontWeight="bold">
              {phone}.
            </Typography>

            <Link onClick={changePhone} sx={{ cursor: 'pointer' }}>
              {t('onboarding.phoneVerification.changePhoneNumber')}
            </Link>
          </Stack>

          <TextField
            variant="outlined"
            fullWidth
            error={!!errors.otpCode?.message}
            inputProps={{ maxLength: 6 }}
            helperText={errors.otpCode?.message && t(errors.otpCode?.message)}
            {...register('otpCode')}
          />

          <Stack direction="row" marginBottom={2}>
            <Typography variant="body1">{t('onboarding.phoneVerification.didNotReceiveCode')}</Typography>

            {count === 0 ? (
              <Link onClick={handleResendCode} sx={{ cursor: 'pointer' }} marginLeft={1}>
                {t('onboarding.phoneVerification.resendCode')}
              </Link>
            ) : (
              <>
                <Typography variant="body1">&nbsp;{t('onboarding.phoneVerification.nextTry')}&nbsp;</Typography>

                <Typography variant="body1" fontWeight="bold">
                  {t('suffix.unit.second', { count })}
                </Typography>
              </>
            )}
          </Stack>

          <LoadingButton variant="contained" size="large" fullWidth type="submit" loading={isSubmitting}>
            {t('button.submit')}
          </LoadingButton>
        </>
      )}
    </Stack>
  );
};

export default PhoneVerification;
