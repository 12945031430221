import { ReactNode, useMemo } from 'react';
import useSiteLookup from '../hooks/useSiteLookup';
import SiteLookupContext from './SiteLookupContext';

type Props = {
  children: ReactNode;
};

const SiteLookupProvider = ({ children }: Props) => {
  const {
    actions: { downloadReport, updateFilter },
    state: { filters, isDownloadingSitesReport, isUserOnFreePlan, remainingSearches },
  } = useSiteLookup();

  const contextValue = useMemo(
    () => ({
      downloadReport,
      filters,
      isDownloadingSitesReport,
      isUserOnFreePlan,
      remainingSearches,
      updateFilterAddress: updateFilter('address'),
      updateFilterRadius: updateFilter('radius'),
    }),
    [downloadReport, updateFilter, filters, isDownloadingSitesReport, isUserOnFreePlan, remainingSearches],
  );

  return <SiteLookupContext.Provider value={contextValue}>{children}</SiteLookupContext.Provider>;
};

export default SiteLookupProvider;
