import { ReactNode, useMemo, useState } from 'react';
import SiteLookupMapContext, { SearchLimit, SelectedSite } from './SiteLookupMapContext';

type Props = {
  children: ReactNode;
};

const SiteLookupMapProvider = ({ children }: Props) => {
  const [isMapExtended, setIsMapExtended] = useState(true);
  const [selectedSite, setSelectedSite] = useState<SelectedSite | null>(null);
  const [searchLimit, setSearchLimit] = useState<SearchLimit | null>(null);
  const [hasSitesIncludedInReport, setHasSitesIncludedInReport] = useState(true);

  const contextValue = useMemo(
    () => ({
      hasSitesIncludedInReport,
      isMapExtended,
      searchLimit,
      selectedSite,
      setHasSitesIncludedInReport,
      setIsMapExtended,
      setSearchLimit,
      setSelectedSite,
      toggleIsMapExtended: () => setIsMapExtended((old) => !old),
    }),
    [hasSitesIncludedInReport, isMapExtended, searchLimit, selectedSite],
  );

  return <SiteLookupMapContext.Provider value={contextValue}>{children}</SiteLookupMapContext.Provider>;
};

export default SiteLookupMapProvider;
