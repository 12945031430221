import { Menu } from '@mui/material';
import CoordinatesMenuItem from './CoordinatesMenuItem';
import SearchMenuItem from './SearchMenuItem';

type Props = {
  coordinates: { lat: number; lng: number };
  isOpen: boolean;
  position?: { left: number; top: number };
  onCoordinates: () => void;
  onSearch: () => void;
  onClose: () => void;
};

const ContextMenu = ({ coordinates: { lat, lng }, isOpen, onClose, onCoordinates, onSearch, position }: Props) => {
  return (
    <Menu
      anchorReference="anchorPosition"
      anchorPosition={position ? { top: position.top, left: position.left } : undefined}
      open={isOpen}
      onClose={onClose}
      elevation={2}
    >
      <CoordinatesMenuItem lat={lat} lng={lng} onClick={onCoordinates} />
      <SearchMenuItem onClick={onSearch} />
    </Menu>
  );
};

export default ContextMenu;
