import { gql } from '@urql/core';
import { SiteFragment } from '../fragments';

const Sites = gql`
  ${SiteFragment}

  query Sites(
    $input: GetSitesRequest!,
  ) {
    sites(
      input: $input,
    ) {
      results {
        ...SiteFragment
      }
      includedInReportCount
      stationsCount
      trackedPortsCount
      limit
      limitExceeded
    }
  }
`;

export default Sites;
