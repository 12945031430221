import { useSitesQuery } from '@domain/index';
import { useContext, useEffect, useMemo } from 'react';
import SiteLookupMapContext from '../context/SiteLookupMapContext';
import { SiteLookupFilters } from '../filters/useSiteLookupFilters';
import SiteLookupList from './SiteLookupList';

type UseSiteLookupListProps = {
  filters: SiteLookupFilters;
};

const useSiteLookupList = ({ filters }: UseSiteLookupListProps) => {
  const { setHasSitesIncludedInReport, setSelectedSite, setSearchLimit } = useContext(SiteLookupMapContext);

  const { data, reexecuteQuery, fetching } = useSitesQuery({
    variables: {
      input: {
        coordinates: filters.address?.coordinates || { latitude: 0, longitude: 0 },
        range: filters.radius,
        stateCode: filters.address?.regionCode || '',
      },
    },
    pause: true,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: execute only when wanted filters update
  useEffect(() => {
    // We can either have the full address OR/AND the coordinates. The coordinates
    // could be present without any address being typed/selected, if the user selects
    // a point on the map.
    if (!filters.address?.fullAddress && !filters.address?.coordinates) {
      return;
    }

    setHasSitesIncludedInReport(true);
    setSearchLimit(null);
    setSelectedSite(null);
    reexecuteQuery();
  }, [filters.address, filters.radius]);

  useEffect(() => {
    if (!data?.sites.results) {
      return;
    }

    setSearchLimit({
      count: data.sites.limit,
      exceeded: data.sites.limitExceeded,
    });
    setHasSitesIncludedInReport(data.sites.includedInReportCount > 0);
  }, [data, setHasSitesIncludedInReport, setSearchLimit]);

  const List = useMemo(
    () => () => (
      <SiteLookupList
        hasSearchResult={!!data?.sites.results}
        fetching={fetching}
        sites={data?.sites.results || []}
        totalCount={data?.sites.stationsCount || 0}
        trackedPortsCount={data?.sites.trackedPortsCount || 0}
      />
    ),
    [data, fetching],
  );

  return {
    data: {
      sites: data?.sites.results || [],
      totalCount: data?.sites.stationsCount || 0,
    },
    List,
    state: {
      fetching,
    },
  };
};

export default useSiteLookupList;
