import { AddressSearchResponse } from '@application/components/address-search/AddressSearch';
import { ReportAggregation } from '@domain/graphql.types';
import { createContext } from 'react';
import { SiteLookupFilters } from '../filters/useSiteLookupFilters';

export type SelectedSite = {
  id: string;
  origin: 'list' | 'map';
};

export type SearchLimit = {
  exceeded: boolean;
  count: number;
};

type SiteLookupContextProps = {
  downloadReport: (aggregation: ReportAggregation) => void;
  filters: SiteLookupFilters;
  isDownloadingSitesReport: boolean;
  isUserOnFreePlan: boolean;
  remainingSearches?: number;
  updateFilterAddress: (address: AddressSearchResponse) => void;
  updateFilterRadius: (radius: number) => void;
};

const SiteLookupContext = createContext<SiteLookupContextProps>({
  downloadReport: () => {},
  filters: { radius: 0 },
  isDownloadingSitesReport: false,
  isUserOnFreePlan: false,
  remainingSearches: undefined,
  updateFilterAddress: () => {},
  updateFilterRadius: () => {},
});

export default SiteLookupContext;
