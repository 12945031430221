import { GeoCoordinates, Site } from '@domain/graphql.types';
import { accessToken } from '@infrastructure/mapbox';
import { feature, featureCollection, bbox as turfBoundingBox, circle as turfCircle, lineString as turfLineString } from '@turf/turf';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { LngLatBoundsLike, MapRef } from 'react-map-gl';
import SiteLookupMapContext from '../context/SiteLookupMapContext';
import { useSitesRequest } from '@domain/index';

const unitedStatesCenter: GeoCoordinates = {
  longitude: -98,
  latitude: 39,
};
const unitedStatesOnlyZoom = 4.75;

const getTurfCircle = (center: GeoCoordinates, radius: number) => turfCircle([center.longitude, center.latitude], radius, { units: 'miles' });

const getTurfBoundingBox = (center: GeoCoordinates, radius: number): LngLatBoundsLike => {
  return turfBoundingBox(getTurfCircle(center, radius)) as LngLatBoundsLike;
};

type Props = {
  center?: GeoCoordinates;
  searchRadius: number;
  sites?: readonly Site[];
};

const useSiteLookupMap = ({ center, searchRadius, sites }: Props) => {
  const { selectedSite, setSelectedSite, setIsMapExtended } = useContext(SiteLookupMapContext);

  const mapRef = useRef<MapRef>(null);

  const { data } = useSitesRequest();

  const allSites = useMemo(() => {
    let allSites = data || [];

    if (sites?.length) {
      allSites = allSites?.filter(({ id }) => !sites.find(({ id: siteId }) => id === siteId));
    }

    const features =
      allSites.map(({ coordinates, includedInReport, trackingState, status }) =>
        feature({ type: 'Point', coordinates: [coordinates.longitude, coordinates.latitude] }, { includedInReport, trackingState, status }),
      ) || [];

    return featureCollection(features);
  }, [data, sites]);

  useEffect(() => {
    if (!center) {
      return;
    }

    setIsMapExtended(false);
  }, [center, setIsMapExtended]);

  useEffect(() => {
    if (!selectedSite || !sites || selectedSite.origin === 'map') {
      return;
    }

    const coords = sites.find(({ id }) => id === selectedSite.id)?.coordinates;

    if (!coords) {
      return;
    }

    mapRef.current?.flyTo({ center: { lat: coords.latitude, lon: coords.longitude } });
  }, [selectedSite, sites]);

  useEffect(() => {
    if (!center) {
      return;
    }

    setTimeout(() => mapRef.current?.fitBounds(getTurfBoundingBox(center, searchRadius)), 50);
  }, [center, searchRadius]);

  const [radiusCircle, radiusLine] = useMemo(() => {
    if (!center) {
      return [];
    }

    const circle = getTurfCircle(center, searchRadius);

    return [circle, turfLineString(circle.geometry.coordinates.flat())];
  }, [center, searchRadius]);

  return {
    actions: {
      setSelectedSite,
    },
    refs: {
      map: mapRef,
    },
    state: {
      allSites,
      displayExtendControl: !!center,
      mapCenter: center || unitedStatesCenter,
      mapZoom: unitedStatesOnlyZoom,
      selectedSite,
    },
    layers: {
      radiusCircle,
      radiusLine,
    },
    map: {
      accessToken,
      style: import.meta.env.VITE_REACT_APP_MAP_BOX_MAP_STYLE,
    },
  };
};

export default useSiteLookupMap;
