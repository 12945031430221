import { Stack } from '@mui/material';
import { useContext } from 'react';
import DownloadReportDialog from './components/DownloadReportDialog';
import MainContent from './components/MainContent';
import SearchBar from './components/SearchBar';
import SiteLookupContext from './context/SiteLookupContext';
import SiteLookupMapProvider from './context/SiteLookupMapProvider';
import SiteLookupProvider from './context/SiteLookupProvider';

const SiteDownloadReportDialog = () => {
  const { isDownloadingSitesReport } = useContext(SiteLookupContext);
  return <DownloadReportDialog open={isDownloadingSitesReport} />;
};

const SiteLookup = () => (
  <SiteLookupProvider>
    <Stack height="100%">
      <SiteLookupMapProvider>
        <SearchBar />
        <MainContent />
      </SiteLookupMapProvider>
    </Stack>

    <SiteDownloadReportDialog />
  </SiteLookupProvider>
);

export default SiteLookup;
