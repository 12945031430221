import { InfoSnackbar } from '@application/components/snackbar/renderSnackbar';
import { FeedbackContext } from '@application/contexts';
import { useReverseGeocodingLookup } from '@infrastructure/mapbox';
import { MapMouseEvent } from 'mapbox-gl';
import { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';

type Props = {
  onSearch: (props: { lat: number; lng: number; fullAddress: string; regionCode: string }) => void;
};

const useSiteLookupMapContextMenu = ({ onSearch }: Props) => {
  const { findNearestLocationFromCoordinates } = useReverseGeocodingLookup();
  const [_, copyToClipboard] = useCopyToClipboard();
  const { t } = useTranslation();
  const { setFeedback } = useContext(FeedbackContext);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
    lng: 0,
    lat: 0,
    mouseX: 0,
    mouseY: 0,
  });

  const close = useCallback(() => {
    setContextMenu({
      visible: false,
      x: 0,
      y: 0,
      lng: 0,
      lat: 0,
      mouseX: 0,
      mouseY: 0,
    });
  }, []);

  const open = useCallback((event: MapMouseEvent) => {
    event.preventDefault();

    const { lng, lat } = event.lngLat;
    event.originalEvent.clientX;

    setContextMenu({
      visible: true,
      x: event.point.x,
      y: event.point.y,
      mouseX: event.originalEvent.clientX,
      mouseY: event.originalEvent.clientY,
      lng,
      lat,
    });
  }, []);

  const copyCoordinatesToClipboard = useCallback(() => {
    copyToClipboard(`${contextMenu.lat}, ${contextMenu.lng}`);
    close();
    setFeedback(<InfoSnackbar title={t('siteLookup.contextMenu.copyFeedback')} onClose={() => setFeedback(null)} />);
  }, [close, copyToClipboard, contextMenu, setFeedback, t]);

  const searchNearby = useCallback(async () => {
    const { fullAddress, regionCode } = await findNearestLocationFromCoordinates(contextMenu);
    onSearch({ lat: contextMenu.lat, lng: contextMenu.lng, fullAddress, regionCode });
    close();
  }, [close, contextMenu, findNearestLocationFromCoordinates, onSearch]);

  return {
    actions: {
      close,
      copyCoordinatesToClipboard,
      searchNearby,
      open,
    },
    state: {
      coordinates: {
        lat: contextMenu.lat,
        lng: contextMenu.lng,
      },
      point: {
        left: contextMenu.mouseX,
        top: contextMenu.mouseY,
      },
      visible: contextMenu.visible,
    },
  };
};

export default useSiteLookupMapContextMenu;
