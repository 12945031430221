import { useGeocodingCore } from '@mapbox/search-js-react';
import { useCallback } from 'react';
import { accessToken } from './mapbox';

const useReverseGeocodingLookup = () => {
  const geocodingCore = useGeocodingCore({ accessToken: accessToken });

  const findNearestLocationFromCoordinates = useCallback(
    async (coordinates: { lat: number; lng: number }) => {
      const response = await geocodingCore.reverse(coordinates, { limit: 1, types: new Set(['place']) });
      if (response.features?.length) {
        const fullAddress = response.features[0].properties.full_address;
        const regionCode = (response.features[0].properties.context?.region as { region_code?: string }).region_code ?? '';
        return { fullAddress, regionCode };
      }

      return { fullAddress: '', regionCode: '' };
    },
    [geocodingCore],
  );

  return {
    findNearestLocationFromCoordinates,
  };
};

export default useReverseGeocodingLookup;
