import { Button, Stack, TextField, Typography, useTheme } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { OnboardingSchemaFormFields } from './OnboardingForm.schema';

type Props = {
  handleContinue: (keys: (keyof OnboardingSchemaFormFields)[]) => () => void;
  isInvitedUser: boolean;
};

const TenantInformation = ({ handleContinue, isInvitedUser }: Props) => {
  const { t } = useTranslation();

  const { typography } = useTheme();

  const {
    register,
    formState: { errors },
    watch,
  } = useFormContext<OnboardingSchemaFormFields>();

  const tenantName = watch('tenantName');

  return (
    <Stack gap={3} alignItems="center">
      <Typography variant="h1" sx={{ marginTop: 0.5, fontSize: typography.h3.fontSize, fontWeight: typography.fontWeightMedium }}>
        {t(`onboarding.tenantInformation.title${isInvitedUser ? 'Invited' : ''}`)}
      </Typography>

      {!isInvitedUser && <Typography variant="body1">{t('onboarding.tenantInformation.subscript')}</Typography>}

      <Stack gap={2} width={400}>
        <TextField
          disabled={isInvitedUser}
          label={t('labels.tenantName')}
          variant="outlined"
          fullWidth
          error={!!errors.tenantName?.message}
          helperText={errors.tenantName?.message && t(errors.tenantName?.message, { field: t('labels.tenantName'), max: 128, min: 3 })}
          {...register('tenantName')}
        />

        <TextField
          disabled={isInvitedUser}
          label={t('labels.tenantAddress')}
          variant="outlined"
          fullWidth
          error={!!errors.address?.message}
          helperText={errors.address?.message && t(errors.address?.message, { field: t('labels.tenantAddress'), max: 128 })}
          {...register('address')}
        />

        <Button variant="contained" size="large" fullWidth onClick={handleContinue(['tenantName', 'address'])}>
          {t('button.continue')}
        </Button>

        {isInvitedUser && (
          <Typography variant="body2" textAlign="center">
            <Trans i18nKey="onboarding.tenantInformation.acceptInvitation" values={{ tenantName }} t={t} />
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default TenantInformation;
