import { gql } from 'urql';

const SiteFragment = gql`
  fragment SiteFragment on Site {
    id
    coordinates {
      latitude
      longitude
    }
    name
    distance
    networkCode
    networkName
    portsCount
    maxSpeed
    trackingState
    status
    includedInReport
  }
`;

export default SiteFragment;
